import PropTypes from 'prop-types'
import React from 'react'
import Logo from '../images/manuela-weisz-logo.svg'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="content">
      <div className="inner">
        <h1>
          <img src={Logo} id="logo" alt="Manuel Weisz - Personal- und Organisationsentwicklung" />
        </h1>

        <p>Umsetzungsberatung<br/> mit Kopf, Herz und Hand</p>

        <br />
        {false &&
        <blockquote>
          «Die Zukunft soll man nicht voraussehen wollen, sondern möglich
          machen.»
          <br />
          <div className="author">Antoine de Saint-Exupéry</div>
        </blockquote>
        }
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
