import React from 'react'
import { Link } from "gatsby"
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <div className="copyright">&copy; Manuela Weisz{' '}|{' '}
            <Link to="/impressum">Impressum</Link>{' '}|{' '}
            <Link to="/datenschutz">Datenschutz</Link>
        </div>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
