import PropTypes from 'prop-types'
import React from 'react'
import ImageProvider from './ImageProvider'

class Main extends React.Component {

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <ImageProvider
              fileName="markus-spiske-nFni-3zOaXg-unsplash.jpg"
              style={{ width: '100%' }}
              alt="Photo by Markus Spiske on Unsplash"
            />
          </span>
          <p>
            Ob groß, mittel oder klein: Ich begreife jedes Projekt als ein
            System, in dem sich Menschen gegenseitig bedingen und manchmal auch
            behindern. Als Coach, Prozessberaterin, Teamentwicklerin,
            Personalentwicklern, Moderatorin und Organisationsentwicklerin
            richte ich meinen Blick von außen auf Strategie, Struktur und Kultur
            gleichermaßen. Methodensicher und achtsam führe ich Menschen und
            Organisationen durch schwierige und herausfordernde Phasen in
            Veränderungsprozessen. Vom ersten Nachdenken über die methodisch
            exzellente Konzeption bis zur praktischen Unterstützung der
            Umsetzung.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <span className="image main">
            <ImageProvider
              fileName="irfan-simsar-wxWulfjN-G0-unsplash.jpg"
              style={{ width: '100%' }}
              alt="Photo by Irfan Simsar on Unsplash"
            />
          </span>
          <h3>Organisationsentwicklung</h3>
          <p>
            Die Arbeitswelt ist im Wandel: Digitalisierung, Globalisierung,
            Innovationsdruck. Als Navigator steure ich mit Ihnen die Projekte
            und schaffe Orientierung. Mit den passenden Methoden entwickeln wir
            fokussierte Zukunftsbilder und pragmatische Strategien. Gemeinsam
            gestalten wir Veränderungen mit und für Menschen, schaffen sinnvolle
            Beteiligung, ehrliche Kommunikation und belastbare Roadmaps.
          </p>

          <h3>Leadership Development</h3>
          <p>
            Basierend auf meiner Erfahrung als Führungskraft und Coach gebe ich
            Denkimpulse, schaffe neue Erfahrungsräume und vermittle Methoden.
            Geleitet von dem Ziel, das Führung Wirksamkeit entfalten soll,
            arbeite ich mit Führungskräften entlang ihrer Praxis an ihrer Rolle,
            an mehr Klarheit für sich und ihre Mitarbeiter, einer
            ressourcenorientierten Kommunikation und dem erfolgreichen Gestalten
            von Veränderungen.
          </p>

          <h3>Corporate Conventions</h3>
          <p>
            Großveranstaltungen sind moderne Lagerfeuer. Sie transportieren
            Informationen, schaffen Symbole und vermitteln ein Wir-Gefühl.
            Corporate Conventions folgen eigenen Gesetzen. Sie erfordern eine
            sehr gute konzeptionelle Vorarbeit, methodische Vielfalt und eine
            sinnvolle Beteiligung der Teilnehmer. Ich entwickle mit Ihnen
            maßgeschneiderte Konzepte und setzen diese um. Hierdurch entsteht
            Begeisterung, die nachhaltig verändert.
          </p>

          <h3>Teamentwicklung</h3>
          <p>
            Keine Spitzenleistung ohne optimale Teamprozesse. In der
            Teamentwicklung klären Teams die entscheidenden Fragen in ihrer
            Zusammenarbeit, finden persönlich zueinander, lösen Konflikte und
            steigern gemeinsam ihre Leistung. Als Team-Coach inspiriere, fordere
            heraus und gebe Sicherheit als vertrauenswürdige Unterstützerin.
          </p>

          <h3>HR-Entwicklung</h3>
          <p>
            HR-Strategie als Teil der Unternehmensstrategie zu verstehen, ist
            die Grundlage für Ihren zukünftigen Erfolg. Die zentrale Frage
            lautet: Was ist unser Beitrag als HR zum Erfolg des Unternehmens?
            Und das unter Anbetracht der aktuellen Entwicklungen. Die
            strategische Personalarbeit sorgt dafür, dass die Mitarbeiter mit
            ihren Kompetenzen auf die aktuellen und künftigen Anforderungen des
            Unternehmens vorbereitet sind. Als HR-Expertin berate ich sie zu
            Entwicklungsbedarfsanalysen, Führungskräfteentwicklungsprogramme
            genauso wie regelmäßige Mitarbeiterbefragungen.
          </p>

          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <ImageProvider
              fileName="968173.jpg"
              style={{ width: '100%' }}
              alt="Photo by Irfan Simsar on Unsplash"
            />
          </span>
          <p>
            Als Diplomsozialwissenschaftlerin berate ich seit über 20 Jahre
            Unternehmen zu vielfältigen Personal- und
            Organisationsentwicklungsthemen. Als interne Beraterin und
            Führungskraft leitete ich jahrelang die Abteilung Personal- und
            Organisationsentwicklung, strategische HR-Projekte und
            Fusionsprojekte. 2013 wechselte ich die Seite und berate seitdem als
            externe Beraterin branchenübergreifend Organisationen in ihrer
            Entwicklung. Mein Wissen teile ich gerne und bin als Dozentin für
            Organisationsentwicklung in Hamburg tätig.
          </p>
          <p>
            Meine Kunden, Konzerne und Mittelstandsunternehmen aus
            unterschiedlichen Branchen, erleben mich als kundenorientierte,
            pragmatische, umsichtige Beraterin auf Augenhöhe. Ich begleite meine
            Kunden sicher und zielgerichtet mit individuellen Konzepten und
            Ideen.
          </p>
          <p>
            Ganz privat: Ich lebe mit meinem Sohn in Hamburg Rissen. Meine freie
            Zeit verbringe ich gerne mit meinem Hund, im Garten und mit einem
            guten Buch.
          </p>
          <p>
            <strong>Mein Stil: systematisch, energiegeladen, zupackend</strong>
          </p>
          <div>
            <a className="button" href={`/Profil-Manuela-Weisz.pdf`} download>Profil herunterladen <small>(PDF, 1,9 MB)</small></a>
          </div>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <span className="image main">
            <ImageProvider
              fileName="adam-solomon-WHUDOzd5IYU-unsplash.jpg"
              style={{ width: '100%' }}
              alt="Photo by Adam Solomon on Unsplash"
            />
          </span>
          <p>
            Das Kennenlernen neuer Menschen bringt neue Impulse und einen
            Mehrwert für alle. Ich würde Sie, Ihre Herausforderungen und Ziele
            gern kennenlernen – auf einen Kaffee. Etwas Süßes dazu sowie meine
            Kompetenzen und Ideen bringe ich mit.
          </p>
          <adress>
            <strong>Manuela Weisz</strong>
            <br />
            Iserdal 1b
            <br />
            22559 Hamburg
            <br />
            <br />
            Phone <a href="tel:+491714069677">+491714069677</a>
            <br />
            E-Mail <a href="mailto:office@manuelaweisz.de">office@manuelaweisz.de</a>
            <br />
          </adress>

          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
